<template>
  <BaseCard
    title="datos del cliente"
    :loading="loading"
    @save-button-click="handleSaveButtonClick"
  >
    <div>
      <ClientProfileForm
        ref="client-profile-form"
        v-model="client"
        :number-visible="true"
      />
      <hr class="mx-2">
      <b-card-title class="m-0 py-1 px-2">
        <b-row align-v="center">
          <b-col
            class="font-weight-bold d-flex align-items-center"
            cols="auto"
          >
            INFORMACIÓN BANCARIA Y DE PAGO
          </b-col>
        </b-row>
      </b-card-title>
      <ClientBillingForm
        ref="client-billing-form"
        v-model="client"
      />
    </div>
  </BaseCard>
</template>

<script>
import ApiRestService from '@/api/base-api'
import ClientProfileForm from '@/components/clients/form/ClientProfileForm.vue'
import ClientBillingForm from '@/components/clients/form/ClientBillingForm.vue'
import BaseCard from '@/components/ui/card/BaseCard.vue'

export default {
  components: {
    BaseCard,
    ClientBillingForm,
    ClientProfileForm,
  },
  data() {
    return {
      loading: false,
      client: {},
    }
  },
  computed: {
    clientProfileForm() {
      return this.$refs['client-profile-form']
    },
    clientBillingForm() {
      return this.$refs['client-billing-form']
    },
  },
  mounted() {
    this.loadClient()
  },
  methods: {
    async loadClient() {
      try {
        this.loading = true
        const response = await ApiRestService.get(this.$route.params.id, this.$data.$constants.RESOURCES.RESOURCE_CLIENTS)
        this.client = response.data
      }  finally {
        this.loading = false
      }
    },
    async handleSaveButtonClick() {
      const formsValidation = await Promise.all([
        this.clientProfileForm.validateForm(),
        this.clientBillingForm.validateForm(),
      ])

      if (formsValidation.some(valid => !valid)) {
        return
      }

      try {
        this.loading = true
        if (this.clientProfileForm.isImageRemoved()) {
          await ApiRestService.deleteImage(this.$route.params.id, this.$data.$constants.RESOURCES.RESOURCE_CLIENTS)
        }

        await ApiRestService.edit(this.$route.params.id, this.client, this.$data.$constants.RESOURCES.RESOURCE_CLIENTS)
        await this.$router.go(-1)
      } catch (error) {
        if (error.response.data?.error) {
          this.$toast.error(error.response.data.error)
        }
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>
</style>
